import { openDB, deleteDB, wrap, unwrap } from "idb";

export async function useIndexDB(dbName, tableName) {
	const dbPromise = await openDB(dbName, 1, {
		upgrade(db) {
			db.createObjectStore(tableName);
		},
	});

	const handler = {
		async get(key) {
			return (await dbPromise).get(tableName, key);
		},
		async set(key, val) {
			return (await dbPromise).put(tableName, val, key);
		},
		async delete(key) {
			return (await dbPromise).delete(tableName, key);
		},
		async clear() {
			return (await dbPromise).clear(tableName);
		},
		async keys() {
			return (await dbPromise).getAllKeys(tableName);
		},
		async getAll() {
			return (await dbPromise).getAll(tableName);
		},
	};

	return { dbPromise, ...handler };
}
