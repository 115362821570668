<template>
	<div class="container">
		<div class="d-flex justify-content-between align-items-center mb-4">
			<h3 class="h3 mb-0">客戶管理</h3>
			<button @click="edit.showModal()" class="btn btn-info flex-shrink-0">新增客戶</button>
		</div>

		<div class="d-flex align-items-center gap-3 mb-4">
			<input
				v-model="searchKeyword"
				@keyup.enter="searchList()"
				type="text"
				placeholder="中文姓名/英文姓名/電話號碼1/電話號碼2"
				class="form-control"
				style="width: 320px"
			/>

			<button @click="searchList()" class="flex-shrink-0 btn btn-primary">查詢</button>

			<select v-model="searchDoctor" @change="searchList()" class="form-select ms-auto" style="width: 150px">
				<option value="">所有引導員</option>
				<option v-for="(value, key) in doctorList" :value="key">{{ value }}</option>
			</select>

			<select v-model="searchType" @change="searchList()" class="form-control" style="width: 150px">
				<option v-for="(name, key) in searchTypeList" :key="key" :value="key">{{ name }}</option>
			</select>
		</div>

		<table class="table table-hover mb-5">
			<thead class="bg-light">
				<tr>
					<th>中文姓名</th>
					<th>英文姓名</th>
					<th class="text-center">性別</th>
					<th>電話號碼1</th>
					<th>電話號碼2</th>
					<th>Email</th>
					<th>客戶ID</th>
					<th class="text-center">會員身份</th>
					<th>引導員</th>
					<th class="text-center" style="width: 50px"></th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="item in pageList" :key="item.id" @click="toDetailPage(item.id)">
					<td data-title="中文姓名">{{ item.name }}</td>
					<td data-title="英文姓名">{{ item.en_name }}</td>
					<td data-title="性別" class="text-center">{{ genderList[item.gender] }}</td>
					<td data-title="電話號碼1">{{ item.phone_1 }}</td>
					<td data-title="電話號碼2">{{ item.phone_2 }}</td>
					<td data-title="Email">{{ item.email }}</td>
					<td data-title="客戶ID">{{ item.sid }}</td>
					<td data-title="會員身份" class="text-center">{{ typeList[item.type] }}</td>
					<td data-title="引導員">
						<template v-for="i in 2">
							<div v-if="item.doctor[i - 1]">{{ item.doctor[i - 1].name }}</div>
						</template>
						<div v-if="item.doctor.length > 2">...</div>
					</td>

					<td class="text-center" @click.stop="edit.showModal(item.id)">
						<a class="link-primary" role="button">編輯</a>
					</td>
				</tr>
			</tbody>
		</table>

		<Pagination
			v-model="pagination.currentPage"
			:records="pagination.records"
			:per-page="pagination.perPage"
			:options="pagination.options"
			@paginate="getPageList"
		/>

		<div ref="editModalRef" class="modal fade" tabindex="-1" data-bs-backdrop="static">
			<div class="modal-dialog modal-dialog-scrollable">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">{{ edit.data.id >= 0 ? "編輯" : "新增" }}客戶</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<Vueform
							ref="editFormRef"
							:schema="editFormSchema"
							:display-errors="false"
							validate-on="step"
						></Vueform>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
						<button type="button" class="btn btn-primary" @click="edit.submit()">確定</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import Swal from "sweetalert2";
import { apiClient, apiGetDoctors } from "@/assets/js/api.js";
import { ref, reactive, onMounted, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Modal } from "bootstrap";
import { useIndexDB } from "@/assets/js/indexDB.js";

let clientIDB;
let doctorIDB;

const store = useStore();
const router = useRouter();

const dataList = ref([]); // 客戶清單
const pageList = ref([]); // 客戶清單分頁顯示

const pagination = reactive({
	currentPage: 1,
	records: 0,
	perPage: 20,
	options: {
		theme: "bootstrap4",
		hideCount: true,
	},
});

const searchTypeList = ref({
	"": "所有資料",
	1: "僅顯示會員",
	2: "僅顯示非會員",
});

const genderList = computed(() => store.state.client.genderList); // 性別選單
const typeList = computed(() => store.state.client.typeList); // 會員身份選單
const classificationList = computed(() => store.state.client.classificationList); // 會員分類選單

const searchKeyword = ref(""); // 篩選關鍵字
const searchDoctor = ref(""); // 篩選引導員
const searchType = ref(""); // 篩選會員
const doctorList = ref([]);

const editModalRef = ref(null);
const editFormRef = ref(null);

const editFormSchema = ref({
	name: {
		label: "中文姓名",
		type: "text",
		rules: ["required", "min:2", "max:20"],
		default: "",
	},
	en_name: {
		label: "英文姓名",
		type: "text",
		rules: ["min:2", "max:20"],
		default: "",
	},
	birthday: {
		label: "西元出生年月日",
		type: "date",
		displayFormat: "YYYY-MM-DD",
		loadFormat: "YYYY-MM-DD",
		valueFormat: "YYYY-MM-DD",
		default: "",
	},
	gender: {
		label: "性別",
		type: "select",
		canClear: false,
		canDeselect: false,
		native: false,
		search: true,
		items: genderList.value,
		default: 0,
	},
	phone_1: {
		label: "電話號碼1",
		type: "text",
		inputType: "tel",
		rules: ["min:2", "max:20"],
		default: "",
	},
	phone_2: {
		label: "電話號碼2",
		type: "text",
		inputType: "tel",
		rules: ["min:2", "max:20"],
		default: "",
	},
	email: {
		label: "電子信箱",
		type: "text",
		inputType: "email",
		rules: ["min:3", "max:60"],
		default: "",
	},
	sid: {
		label: "客戶ID",
		type: "text",
		rules: ["min:2", "max:20"],
		default: "",
	},
	type: {
		label: "會員身份",
		type: "select",
		canClear: false,
		canDeselect: false,
		native: false,
		search: true,
		items: typeList.value,
		default: 0,
	},
	classification: {
		label: "會員分類",
		type: "select",
		canClear: false,
		canDeselect: false,
		native: false,
		search: true,
		items: classificationList,
		default: 1,
	},
	note: {
		label: "評估結果",
		type: "textarea",
		rules: ["min:2", "max:60"],
		default: "",
		autogrow: false,
	},
	doctor: {
		label: "引導員",
		type: "tags",
		search: true,
		closeOnSelect: false,
		items: {},
		default: [],
	},
});

const edit = reactive({
	modal: {},
	data: {
		id: -1, // -1: 新增模式 ; >= 0: 編輯模式
	},
	async showModal(id) {
		edit.data.id = -1;
		editFormRef.value.reset();

		// edit
		if (id) {
			let detailData = await clientIDB.get(id);
			edit.data.id = detailData.id;

			editFormRef.value.load(detailData);
		}

		setTimeout(() => {
			editFormRef.value.resetValidators();
			edit.modal.show();
		}, 100);
	},
	submit() {
		const data = editFormRef.value.data;
		editFormRef.value.validate().then(() => {
			if (!editFormRef.value.invalid) {
				setPageLoading(true);

				data.doctor = data.doctor.map((item) => parseInt(item));

				let apiOptions = {
					method: "",
					data,
				};

				if (edit.data.id < 0) {
					//add
					apiOptions.method = "post";
				} else {
					//edit
					apiOptions.method = "put";
					apiOptions.id = edit.data.id;
				}

				apiClient(apiOptions)
					.then((response) => {
						setPageLoading(false);
						if (response.data.status == "0") {
							Swal.fire({
								icon: "success",
								text: response.data.message,
							});

							getList();
							edit.modal.hide();
						} else {
							Swal.fire({
								icon: "warning",
								text: response.data.message,
							});
						}
					})
					.catch(() => {
						setPageLoading(false);
					});
			}
		});
	},
});

const setPageLoading = (isLoading) => store.dispatch("setPageLoading", isLoading);

//取得 引導員選單
function getDoctorList() {
	setPageLoading(true);

	apiGetDoctors()
		.then(async (response) => {
			setPageLoading(false);
			if (response.data.status == "0") {
				const data = response.data.data;
				editFormSchema.value.doctor.items = data;

				doctorList.value = data;

				doctorIDB.clear();
				for (let key in data) {
					await doctorIDB.set(parseInt(key), {
						id: key,
						name: data[key],
					});
				}
			} else {
				Swal.fire({
					icon: "warning",
					text: response.data.message,
				});
			}
		})
		.catch(() => {
			setPageLoading(false);
		});
}

//取得 客戶清單
function getList() {
	setPageLoading(true);

	apiClient({
		method: "get",
		data: {
			text: searchKeyword.value,
			type: searchType.value,
		},
	})
		.then(async (response) => {
			let data = response.data.data;
			dataList.value = data;

			clientIDB.clear();
			data.forEach((item) => {
				clientIDB.set(item.id, item);
			});

			setPageLoading(false);
		})
		.catch(() => {
			setPageLoading(false);
		});
}

async function searchList() {
	const list = await clientIDB.getAll();

	dataList.value = list.filter((item) => {
		//關鍵字篩選
		let keywords = true;
		if (searchKeyword.value.length > 0) {
			keywords = false;

			const keys = ["name", "en_name", "phone_1", "phone_2"];
			for (let i = 0; i < keys.length; i++) {
				const iValue = item[keys[i]] == null ? "" : item[keys[i]];
				const iKeywords = iValue.indexOf(searchKeyword.value) >= 0;

				if (iKeywords) {
					keywords = true;
					break;
				}
			}
		}

		// 引導員篩選
		const doctor =
			searchDoctor.value.length == 0
				? true
				: item.doctor.find((doctor) => doctor.id == parseInt(searchDoctor.value));

		// 會員篩選
		const type = searchType.value.length == 0 ? true : searchType.value == item.type;

		return keywords && type && doctor;
	});
}

//取得 客戶清單 分頁
function getPageList(currentPage) {
	let startIndex = (currentPage - 1) * pagination.perPage;
	let endIndex = startIndex + pagination.perPage;

	pageList.value = dataList.value.slice(startIndex, endIndex);
}

//前往 客戶資料明細頁面
function toDetailPage(id) {
	if (id) {
		router.push({
			name: "ClientDetail",
			params: { id },
		});
	}
}

watch(
	dataList,
	(val) => {
		pagination.currentPage = 1;
		pagination.records = val.length;
		getPageList(1);
	},
	{ deep: true }
);

onMounted(async () => {
	clientIDB = await useIndexDB("clients", "client");
	doctorIDB = await useIndexDB("doctors", "doctor");

	edit.modal = new Modal(editModalRef.value);
	editModalRef.value.addEventListener("hidePrevented.bs.modal", (event) => {
		Swal.fire({
			icon: "info",
			text: "是否要關閉表單?",
			showCancelButton: true,
			focusConfirm: false,
			confirmButtonText: "是",
			cancelButtonText: "否",
		}).then((result) => {
			if (result.isConfirmed) {
				edit.modal.hide();
			}
		});
	});

	getList();
	getDoctorList();
});
</script>

<style lang="scss" scoped>
table {
	tbody tr {
		cursor: pointer;
	}

	th {
		white-space: nowrap;
	}

	td {
		white-space: nowrap;
		vertical-align: middle;
	}
}
</style>
